import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);
const state = {
    loginState:null,
    isLogin:false,
    tokenAuth:null,
};

const store = new Vuex.Store({
    state,

    getters:{
        loginState: (state)=>{
            return state.loginState;
        },
        gLogin: (state)=>{
            return state.isLogin;
        },
    },
    actions:{
        loginState(context,loginState){
            context.commit('loginState',loginState)
        },
        aLogin(context){
            context.commit('mLogin')
        },
        aLogout(context){
            context.commit('mLogout')
        }

    },
    mutations:{
        loginState(state,loginState){
            state.loginState = loginState;
        },
        mLogin(state){
            state.isLogin = true;
            state.tokenAuth = localStorage.getItem('mtoken_auth');
        },
        mLogout(state){
            state.isLogin = false;
            state.tokenAuth = null;
        },
        mReset(state){
            state.isLogin = false;
        }

    },
    plugins:[createPersistedState()]
});

export default store
