import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../vuex'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  //console.log("isLogin",store.getters.gLogin);
  if (!store.getters.gLogin) {
    next()
    return
  }  
  next('/')

  // if (!store.getters.loginState) {
  //   //console.log('!loginData:',store.getters.loginData);
  //   next()
  //   return
  // }  
  // next('/')
}

const ifAuthenticated = (to, from, next) => {
  //console.log('ifAuthenticated',store.getters.gLogin);
  if (store.getters.gLogin) {
    next()
    return
  }
  next('/login')

  // if (store.getters.loginState) {
  //   console.log('loginState:',store.getters.loginState);
  //   next()
  //   return
  // }
  // next('/')
}



const routes = [
  { path: '/login',name: 'login',component: () => import("../views/Login.vue"), beforeEnter:ifNotAuthenticated, },
  { path: '/logout',name: 'logout',component: () => import("../views/Logout.vue"),},  
  { path: '/dashboard', name: 'dashboard', component: () => import("../views/dashboard/Dashboard.vue"), beforeEnter:ifAuthenticated, },
  { path: '/transactions/recentlist', name: 'transactions-recentlist', component: () => import("../views/transactions/TransactionsRecentList.vue") },
  { path: '/transactions/detail/:process_reference_no', name: 'point_transaction_details', component: () => import("../views/transactions/TransactionDetails.vue") },  
  { path: '/transactions/search', name: 'point_transactions_search', component: () => import("../views/transactions/TransactionsSearch.vue") },
  { path: '/evoucher/recentlist', name: 'evoucher-recentlist', component: () => import("../views/evoucher/EvoucherRecentList.vue") },
  { path: '/evoucher/detail/:process_reference_no', name: 'evoucher-details', component: () => import("../views/evoucher/EvoucherDetails.vue") },  
  { path: '/evoucher/search', name: 'evoucher-search', component: () => import("../views/evoucher/EvoucherSearch.vue") },
  { path: '/evoucher/reimbursement', name: 'evoucher-reimbursement', component: () => import("../views/evoucher/EvoucherReimbursement.vue") },
  { path: '/evoucher/reimbursement/detail/:req_ref_no', name: 'evoucher-reimbursement-details', component: () => import("../views/evoucher/EvoucherReimbursementDetails.vue") },    

  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '/', redirect: '/login' },
  { path: '*', redirect: '/login' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: '/adminportal',
  routes
})

export default router
