<template>
  <nav v-if="isLoggedIn">
    <!-- <nav> -->
    <v-app-bar app class="blue darken-3" elevation="0">
            <v-app-bar-nav-icon @click.stop="toggleMini = !toggleMini"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>

    <v-menu offset-y>
<template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="menu in menus" :key="menu.title" route :to="menu.route">
              <v-icon left>{{ menu.icon }}</v-icon>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="flogout">
              <v-icon left>mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>

        </v-list>
    </v-menu>        
    </v-app-bar>

    
    <v-navigation-drawer
        class="blue darken-2" 
        v-model="sidebarMenu"
        app
        floating
            :permanent="sidebarMenu"
            :mini-variant.sync="mini"
    >
        <v-list dense class="blue darken-4">
                <v-list-item>
                    <v-list-item-action>
                        <v-icon @click.stop="toggleMini = !toggleMini">mdi-chevron-left</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <h3 class=" white--text">Merchant Portal</h3>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list>
        
        <template v-for="item in items">
        <v-list-group no-action :value="false" :key="item.title" v-if="item.sub">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon class="white--text">{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="white--text caption">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item v-for="submenu in item.submenu" :key="submenu.title" router :to="submenu.href">
                <v-list-item-title class="white--text caption">
                    <v-icon x-small class="white--text">mdi-minus</v-icon>
                    {{ submenu.title }}</v-list-item-title>
            </v-list-item>
        </v-list-group>
        <v-list  v-else :key="item.title">
            <v-list-item router :to="item.href">
                <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title  class="white--text caption">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </template>
         
    </v-navigation-drawer>

  </nav>
</template>

<script>
//import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      sidebarMenu:true,
      toggleMini:false,
      items: [
        { sub:false,title:"Dashboard", href:"/dashboard", icon:"mdi-monitor-dashboard" },
        { sub:true,title:"Transactions", href:"/transactions", icon:"mdi-sale", 
            submenu:[
                {title:"Recent List", href:"/transactions/recentlist"},
                {title:"Search", href:"/transactions/search"},
                {title:"Summary", href:"/transactions/summary"},
            ] 
        },
        // { sub:false,title:"POS", href:"/pos", icon:"mdi-calculator" },                
        { sub:true,title:"e-Voucher", href:"/redemptions", icon:"mdi-gift", 
            submenu:[
                {title:"Recent List", href:"/evoucher/recentlist"},
                {title:"Search", href:"/evoucher/search"},
                {title:"Summary", href:"/evoucher/summary"},
                {title:"Reimbursement", href:"/evoucher/reimbursement"},
            ] 
        },                
      ],
      menus:[
          { title:"Profile", route:"/admin/profile", icon:"mdi-account-edit" },
          { title:"Change Password", route:"/admin/changepassword", icon:"mdi-lock" },
      ],
        
        events:['click','mousemove'],
    }
  },
      computed: {
      mini() {
        return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
      },
      //...mapGetters(['gLogin']),
      isLoggedIn : function(){ return this.$store.getters.gLogin}
      
    },
    watch:{
        isLoggedIn: function(){
            //console.log(this.$store.state.isLogin);
        }
    },
    methods:{
      flogout(){
        //console.log(this.loginData);
        localStorage.removeItem('mtoken_auth');
        localStorage.removeItem('loginData');
        localStorage.removeItem('login_id');
        localStorage.removeItem('isLogin');
        //this.$store.dispatch('loginState',null);
        this.$store.dispatch('aLogout');
        
        this.$router.push('/login')
        //this.loginData = null;
        
      }
    },
    mounted(){
    },
    created() {
        //this.loginData = localStorage.getItem('loginData');

},


}
</script>

<style>
</style>